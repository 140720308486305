/* @tailwind base;
@tailwind components;
@tailwind utilities; */

.table th {
  font-weight: 600;
  text-transform: uppercase;
  font-size: 0.8rem; /* Original: 0.75rem */
  color: #495057;
  white-space: nowrap;
  cursor: pointer;
  padding: 0.75rem 1rem; /* Original: 0.5rem 0.75rem */
  vertical-align: middle; /* Align header text vertically */
}

.table td {
  font-size: 0.9rem; /* Original: 0.85rem */
  vertical-align: middle;
  padding: 0.75rem 1rem; /* Original: 0.5rem 0.75rem */
}

.table .btn-sm {
  padding: 0.25rem 0.5rem; /* Original: 0.2rem 0.4rem */
  font-size: 0.8rem; /* Original: 0.75rem */
}

.dropdown-menu {
  font-size: 0.9rem; /* Original: 0.85rem */
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: rgba(0, 0, 0, 0.02);
}

.table th svg,
.table td svg {
  vertical-align: middle; /* Align icons vertically */
}

.table th svg {
  margin-right: 0.5rem; /* Original: 0.25rem */
}

.tooltip {
  font-size: 0.85rem; /* Original: 0.8rem */
}

/* dashboard top part */
.fuel-theft-page .card {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  border: 1px solid black;
}

.fuel-theft-page .card-title {
  color: #6c757d;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.fuel-theft-page .form-control-sm {
  font-size: 0.875rem;
}

.fuel-theft-page h2 {
  color: #343a40;
  font-weight: 600;
}
